import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
   const [storeId, setStoreId] = useState(process.env.REACT_APP_STORE_ID);
   const [serviceId, setServiceId] = useState(null);
   const [serviceDate, setServiceDate] = useState(null);
   const [serviceTime, setServiceTime] = useState(null);
   const [firstName, setFirstName] = useState(localStorage.getItem('firstName') || null);
    const [lastName, setLastName] = useState(localStorage.getItem('lastName') || null);
    const [specialRequest, setSpecialRequest] = useState(localStorage.getItem('specialRequest') || null);
    const [email, setEmail] = useState(localStorage.getItem('email') || null);
    const [phone, setPhone] = useState(localStorage.getItem('phone') || null);
    const [serviceName, setServiceName] = useState(null);
    const [price,setPrice] = useState(null);
    const [numOfCustomers,setNumOfCustomers] = useState(1);
    const [employeeIds,setEmployeeIds] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]); //track if customer selected employee or not

    const [feedback,setFeedback] = useState(null);

   return (
      <StateContext.Provider value={{feedback,setFeedback,serviceId,setServiceId,serviceName, setServiceName, price,setPrice,serviceDate,setServiceDate, serviceTime, setServiceTime, firstName,setFirstName, lastName,setLastName,email,setEmail,phone,setPhone,storeId, numOfCustomers, setNumOfCustomers, employeeIds,setEmployeeIds,selectedStatus,setSelectedStatus,specialRequest,setSpecialRequest}}>
         {children}
      </StateContext.Provider>
   );
   };
   
export const useStateContext = () => useContext(StateContext);