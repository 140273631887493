import {
   Button,
   Grid,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   Typography,
} from '@mui/material';
import React, { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import SpaIcon from '@mui/icons-material/Spa';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { useStateContext } from '../Contexts/ContextProvider';
import moment from 'moment';
import { useMakeReservation2 } from '../hooks/useMakeReservation2';

const Review = ({ firstStep,nextStep }) => {
   const {
      firstName,
      lastName,
      email,
      phone,
      price,
      serviceDate,
      serviceTime,
      serviceName,
      serviceId,
      numOfCustomers,
      employeeIds,
      selectedStatus,
      specialRequest,
   } = useStateContext();
   const [error, setError] = useState(null);
   const { isLoading, makeReservation2 } = useMakeReservation2();

   const Previous = (e) => {
      e.preventDefault();
      firstStep();
   };
   const submitData = () => {
      let values = {
         phone: phone,
         first_name: firstName,
         last_name: lastName,
         email: email,
         service_date: moment(serviceDate).format('YYYY-MM-DD'),
         service_time: moment(serviceTime, 'HH:mm:ss').format('hh:mm A'),
         service_id: serviceId,
         service_name: serviceName,
         store_id: process.env.REACT_APP_STORE_ID,
         num_of_customers: numOfCustomers,
         employee_ids: employeeIds,
         selected_status: selectedStatus,
         special_request: specialRequest,
      };
       //update local storage
      localStorage.setItem('phone', phone);
      localStorage.setItem('first_name', firstName);
      localStorage.setItem('last_name', lastName);
      localStorage.setItem('email', email);
      localStorage.setItem('special_request', specialRequest);
      makeReservation2(values, {
         onSuccess: () => {   
            console.log('success');
            nextStep();
         },
         onError: (error) => {
            console.log('error', error);
            setError(error.response.data);
         },
      });
   };
   let strPrice = '$' + Number(price).toFixed(2);
   let strDateTime =
      moment(serviceDate).format('YYYY-MM-DD') +
      ' ' +
      moment(serviceTime, 'HH:mm:ss').format('hh:mm A');
   return (
      <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems='center'
            justifyContent='flex-start'
            direction='column'
         >
            <Grid item xs={12} md={6}>
               <Typography variant='h6'>Please review your booking</Typography>
               <div>
                  <List dense={false}>
                     <ListItem>
                        <ListItemAvatar>
                           <CalendarMonthIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={strDateTime} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <SpaIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={serviceName} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <AttachMoneyIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={strPrice} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <PeopleIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={firstName + ' ' + lastName} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <PhoneIphoneIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={phone} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <EmailIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={email} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <SpeakerNotesIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={specialRequest} />
                     </ListItem>
                  </List>
               </div>
            </Grid>
         </Grid>
         
         {error !== '' && error !== null ? (
            <Typography variant='body1' color='error'>
               {error}
            </Typography>
         ) : (
            <Typography variant='body1' color='error'>
               {
                  "Please press the 'Book Now' button to confirm your booking. You will receive a confirmation text message."
               }
            </Typography>
         )}

         <Grid
            container
            spacing={10}
            alignItems='flex-end'
            direction='row'
            justifyContent='flex-end'
         >
            <Grid item xs={2}>
               <Button onClick={Previous}>Back</Button>
            </Grid>
            <Grid item xs={6}>
               <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={() => submitData()}
               >
                  BOOK NOW
               </Button>
            </Grid>
         </Grid>
      </React.Fragment>
   );
};

export default Review;
